





































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const NSUser = namespace('storeUser');
const NSDocuments = namespace('storeProjectDocuments');

import '../scss/DocumentsComposite.scss';
import { IDocumentsState, IDocument } from '@store/modules/project-documents/Interfaces';
import { ProjectDocumentsMutations } from '@store/modules/project-documents/Types';

@Component({
    name: 'DocumentsComposite',

    components: {
        BaseComposite: () => import('@components/BaseComposite/BaseComposite.vue'),
    },
})

export default class DocumentsComposite extends Vue {
    @NSUser.Getter('userRightOnlyGSN') userRightOnlyGSN!: boolean;
    @NSDocuments.Getter('documentsWithoutRecursion') documentsWithoutRecursion!: IDocument[];
    @NSDocuments.Getter('compositeShow') compositeShow!: boolean;
    @NSDocuments.State((state: IDocumentsState) => state.viewingDocument) viewingDocument!: IDocument;
    @NSDocuments.Mutation(ProjectDocumentsMutations.M_SET_VIEWING_DOCUMENT) setViewingDocument!: (document: IDocument | null) => void;

    get documentsIsFolder() {
        return this.documentsWithoutRecursion.filter(item => item.isFolder);
    }

    @Watch('compositeShow')
    onCompositeShowToggle() {
        if (!this.compositeShow) {
            this.setViewingDocument(null);
        }
    }

    childrenIsFolder(work) {
        return work.children.filter(item => item.isFolder);
    }
}

